import React from "react";

export default function About() {
  return (
    <div className="scroll-hider">
      <label className="block mb-2 text-lg  font-bold text-gray-900 dark:text-white">
        
      </label>
      <p className="dark:text-gray-300">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
        pariatur itaque nisi quas eum officiis reprehenderit quasi sunt.
        Repellendus reiciendis libero tempore quasi? Doloremque exercitationem
        in porro nobis ducimus quam dignissimos aut. Recusandae consequatur
        accusantium pariatur blanditiis saepe deserunt magni omnis quasi sint,
        labore reprehenderit ratione! Architecto rem nobis assumenda! Numquam
        officia quos quam voluptate ex adipisci rerum error corrupti ducimus,
        vero inventore exercitationem soluta a? Quidem cupiditate, excepturi
        maxime optio dolor aspernatur vitae soluta voluptate est fugit dolorum
        esse atque beatae praesentium corporis deleniti molestias, amet earum,
        nam quis deserunt suscipit exercitationem magnam eveniet. Similique quo
        nostrum at dolorem nemo ducimus cupiditate quis voluptatum eligendi.
        Labore expedita vero iure tempore dolore accusamus. Hic, necessitatibus
        laboriosam. Assumenda minus, eligendi, inventore iure fugiat eum
        accusantium maxime perspiciatis eveniet quibusdam dignissimos quod
        suscipit dolor, vel nihil voluptas! Dolorem blanditiis animi,
        consectetur omnis temporibus commodi ad necessitatibus, ullam cupiditate
        cum ducimus magni molestiae? Nemo enim corrupti, excepturi vel quis
        repellendus quisquam necessitatibus error illo aliquam tenetur
        architecto expedita esse nostrum asperiores provident. <br />
        <br /> quia dolorum dolorem! Ad illo saepe dignissimos vero molestias
        iusto maiores tempora voluptate aut voluptatem nemo, error tenetur
        repudiandae nobis sunt sed minima atque. Quas, nostrum eveniet cumque
        sit veritatis dignissimos libero mollitia. Exercitationem voluptates
        voluptas consequatur official. fugiat eos iusto earum illum nihil
        ratione accusamus aliquid vel esse iure facilis aspernatur, nobis
        debitis laboriosam, excepturi dicta iste distinctio. Cupiditate nesciunt
        iste tempore, aspernatur perferendis vitae repellendus nostrum aut esse
        et? Delectus et officiis ex quidem doloremque voluptatibus ea nobis,
        consequuntur, quasi esse temporibus. Suscipit aliquam commodi alias quos
        at velit ex eum? Ea deserunt magnam consequatur illo in voluptatum modi.
        Officia facilis suscipit inventore recusandae aliquid quod debitis
        voluptate? Exercitationem fugit ratione libero. Quo consequuntur
        similique facere quaerat reprehenderit labore et porro? Molestias vitae
        labore consectetur voluptatibus praesentium quam. Lorem ipsum dolor, sit
        amet consectetur adipisicing elit. Vel ipsa dolor, veritatis illum,
        beatae ea neque, ratione eos nostrum esse quidem similique cupiditate?
        Sapiente corrupti nam iure <br />
        debitis ipsam aliquid! Lorem ipsum dolor sit amet consectetur,
        adipisicing elit. Ex ullam fugit aspernatur quaerat incidunt iusto
        quidem, aut quos explicabo vitae libero error maiores! Asperiores
        doloremque non voluptatem, totam expedita inventore quasi nisi
        perferendis exercitationem suscipit, eligendi animi blanditiis ducimus
        hic deserunt nobis. Nesciunt error maiores, facilis ullam esse iste
        praesentium doloribus modi ea molestiae natus nobis, dolores inventore
        fugit quos architecto blanditiis asperiores molestias, aspernatur veniam
        beatae minus! Ad, dolore debitis, obcaecati voluptates amet totam minus
        illum vitae dolores cupiditate consequatur voluptatem tenetur autem
        corporis, aliquid libero repellat cum. Reprehenderit labore alias
        dolores rem veniam repudiandae sint itaque? Labore dolorum laudantium
        eius iste. Nisi, expedita. Id asperiores facilis cum sapiente illo quod
        officia atque deserunt eveniet? Consequuntur, eligendi dolorum totam
        exercitationem voluptatibus debitis! Dolores rerum, illum perferendis
        exercitationem reprehenderit at, maiores voluptatum repellat repellendus
        facere beatae nam minus ipsum nobis accusamus sint nostrum quis
        recusandae est sit quos sed, dolore fugiat! Aliquid ipsum natus fugit
        commodi pariatur quaerat, voluptatibus modi soluta enim totam excepturi
        quia, ad incidunt voluptate quae voluptatum ullam ex, quo esse
        dignissimos at officia quisquam aliquam itaque. Sint nostrum animi
        deleniti? Ab nobis eos sit. Voluptatem, aspernatur. Beatae, cupiditate
        doloribus animi dolorum blanditiis explicabo perspiciatis. Eligendi,
        dignissimos.
      </p>
    </div>
  );
}
